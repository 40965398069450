// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import debug from 'debug'

import { Button, Dialog, DialogContent, Grid, IconButton, Typography, useTheme } from '@mui/material'
import Icon from '@mdi/react'

import { icons } from 'globals/constants/icons'

import { useAuth } from 'components/AuthProvider'

// ? ---
// ?	Types
// ? ---
type Props = {
	state: boolean
	close: () => void
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-LoginSignupDialog'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function LoginSignupDialog({ close, state }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const theme = useTheme()
	const { openLogin, openSignup } = useAuth()

	// * ---
	// *	Return: Good
	// * ---

	return (
		<Dialog
			open={state}
			sx={{
				'& .MuiDialog-paperFullWidth': {
					maxWidth: 650,
				},
			}}
			fullWidth={true}
			onClose={() => {
				close()
			}}
			aria-labelledby={namespace}
		>
			<DialogContent
				id={namespace}
				className={'dragHandle'}
				sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}
			>
				<Grid container direction='row' justifyContent='space-between' alignItems='center'>
					<Grid item>
						<Typography variant={'h2'}>{`Login`}</Typography>
					</Grid>
					<Grid item>
						<IconButton
							aria-label='Close'
							onClick={() => {
								close()
							}}
						>
							<Icon path={icons.closeIcon} size={1} />
						</IconButton>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogContent>
				<Typography variant={'body1'} sx={{ mt: 1, mb: 1 }}>
					{`CDNR will host your resource on our super fast CDN.`}
				</Typography>
				<Typography variant={'body1'} sx={{ mt: 1, mb: 1 }}>
					{`To do this you first you need to login to an account.`}
				</Typography>
				<Grid
					container
					direction='row'
					justifyContent='space-between'
					alignItems='flex-start'
					spacing={1}
					sx={{ pt: 1 }}
				>
					<Grid item xs={6}>
						<Button
							variant={'contained'}
							size={'large'}
							fullWidth
							onClick={() => {
								close()
								openLogin()
							}}
						>
							Login
						</Button>
					</Grid>
					<Grid item xs={6}>
						<Button
							variant={'contained'}
							size={'large'}
							fullWidth
							onClick={() => {
								close()
								openSignup()
							}}
						>
							Signup
						</Button>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	)
}
