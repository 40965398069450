// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import debug from 'debug'
import { useDebounce } from 'use-debounce'

import { Button, Grid } from '@mui/material'

import { useAuth } from 'components/AuthProvider'
import Loading from 'components/Loading'

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-WaitForAuth'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Types
// ? ---
type Props = {
	children: JSX.Element
}

// ? ---
// ?	Component
// ? ---
export default function WaitForAuth({ children }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const { isAuth, isLoading, isLoggingIn, openLogin, openSignup, user } = useAuth()

	// * ---
	// *	State
	// * ---
	const [appReady, $appReady] = React.useState(false)

	// * ---
	// *	Loading State
	// * ---
	const [debouncedIsLoading] = useDebounce(isLoading || isLoggingIn, 500, {
		leading: true,
		trailing: true,
	})

	// * ---
	// *	Effect: App Ready
	// * ---
	React.useEffect(() => {
		if (!isLoading && !isLoggingIn) {
			setTimeout(() => {
				$appReady(true)
			}, 1000)
		}
	}, [isLoading, isLoggingIn])

	// * ---
	// *	Return: No logged in user
	// * ---
	if (!appReady) return <></>
	if (debouncedIsLoading) return <Loading />
	if (!isAuth || !user) {
		return (
			<Grid
				container
				direction='row'
				justifyContent='space-between'
				alignItems='flex-start'
				spacing={1}
				sx={{ pb: 2 }}
			>
				<Grid item xs={6}>
					<Button variant={'outlined'} size={'large'} fullWidth onClick={() => openLogin()}>
						Login
					</Button>
				</Grid>
				<Grid item xs={6}>
					<Button variant={'outlined'} size={'large'} fullWidth onClick={() => openSignup()}>
						Signup
					</Button>
				</Grid>
			</Grid>
		)
	}

	// * ---
	// *	Return: Good
	// * ---
	return React.cloneElement(children)
}
