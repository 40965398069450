// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import debug from 'debug'

import { Box, Divider, useTheme } from '@mui/material'

import { IFieldProps } from 'globals/IFieldProps'

import BooleanSwitch from './Fields/BooleanSwitch'
import ButtonGroup from './Fields/ButtonGroup'
import Dropdown from './Fields/Dropdown'
import SliderRange from './Fields/SliderRange'
import Text from './Fields/Text'

// ? ---
// ?	Types
// ? ---
type Props = {
	formik: any
	field: IFieldProps
	subject?: any
	close?: () => void
	pb?: number
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Global-Dialogs-UpsertFields-UpsertFields'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function Field({ field, formik, pb }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const theme = useTheme()

	// * ---
	// *	Return
	// * ---
	return (
		<Box sx={{ pb: pb || 2 }}>
			{field.type === 'dropdown' ? (
				<Dropdown field={field} formik={formik} />
			) : field.type === 'button-group' ? (
				<ButtonGroup field={field} formik={formik} />
			) : field.type === 'switch' ? (
				<BooleanSwitch field={field} formik={formik} />
			) : field.type === 'slider' ? (
				<SliderRange field={field} formik={formik} />
			) : field.type === 'textarea' ? (
				<Text field={field} formik={formik} multiline={true} />
			) : field.type === 'divider' ? (
				<Box
					sx={{ '& *, & *:before, & *:after': { borderColor: `${theme.palette.text.secondary} !important` } }}
				>
					<Divider sx={{ color: theme.palette.text.primary, fontSize: theme.typography.caption.fontSize }}>
						{field.label}
					</Divider>
				</Box>
			) : (
				<Text field={field} formik={formik} />
			)}
		</Box>
	)
}
