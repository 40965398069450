// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import debug from 'debug'

import { FormControl, FormControlLabel, FormHelperText, Stack, Switch, Typography } from '@mui/material'
import { get } from 'lodash'

import { IFieldProps } from 'globals/IFieldProps'

import { helperText } from 'components/Forms/helperHelper'

// ? ---
// ?	Types
// ? ---
type Props = {
	formik: any
	field: IFieldProps
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Global-Dialogs-UpsertFields-BooleanSwitch'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function BooleanSwitch({ field, formik }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const helper = helperText({ field, formik })
	const valueTrue = field.valueTrue || true
	const valueFalse = field.valueFalse || false

	// * ---
	// *	Return
	// * ---
	return (
		<FormControl variant='outlined' fullWidth>
			<Typography component='legend' variant='caption' sx={{ ml: 1, mr: 1 }}>
				{field.label}
			</Typography>
			<Stack direction='row' justifyContent='flex-start' alignItems='center'>
				{field.labelFalse && (
					<Typography sx={{ display: 'inline-block', ml: 1 }}>{field.labelFalse}</Typography>
				)}
				<FormControlLabel
					control={
						<Switch
							id={field.property}
							name={field.property}
							checked={get(formik?.values, field.property, field.defaultValue) !== valueFalse}
							onChange={(event) =>
								formik.setFieldValue(field.property, event.target.checked ? valueTrue : valueFalse)
							}
						/>
					}
					label={field.labelTrue || 'True'}
					sx={{ ml: 0.5, mr: 0.5 }}
				/>
			</Stack>
			{helper.active && <FormHelperText error={helper.isError}>{helper.message}</FormHelperText>}
		</FormControl>
	)
}
