// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import debug from 'debug'

import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material'
import { map } from 'lodash'

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Faq'
const log = debug(`app:${namespace}`)

// ? ---
// ?	View
// ? ---
export default function Faq(): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')

	const questions = [
		{
			question: 'What is the cost of CDNR?',
			answer: '$1 per URL per month for unlimited traffic.',
		},
		{
			question: 'Need to speed up a slow external JavaScript file?',
			answer: 'CDNR can significantly improve the performance of your external JavaScript files by caching and serving them from our CDN. This reduces latency and accelerates the delivery of these scripts, resulting in faster page load times for your users.',
		},
		{
			question: 'Experiencing LCP issue longer than 4s?',
			answer: `CDNR addresses LCP (Largest Contentful Paint) issues by optimizing content delivery, ensuring that your website's largest and most important elements load within the crucial 4-second threshold. This results in improved page load times and a better user experience.`,
		},
		{
			question: 'Encountering flaky 3rd party resource?',
			answer: 'CDNR resolves stability issues with your 3rd party resources by caching and serving them from our CDN. This ensures a more reliable and consistent experience for your users, eliminating any flakiness in the delivery of external resources.',
		},
		{
			question: 'What is a CDN?',
			answer: 'A CDN (Content Delivery Network) is a network of servers strategically located around the world to deliver web content, such as images, videos, and scripts, quickly and efficiently to users based on their geographic location.',
		},
		{
			question: 'Why should I use a CDN?',
			answer: 'A CDN improves website performance by reducing latency, accelerating content delivery, and enhancing overall user experience. It also helps distribute server load and provides better reliability.',
		},
		{
			question: 'How does a CDN work?',
			answer: "CDN works by caching and storing copies of your website's static content on servers in multiple locations. When a user requests a page, the content is delivered from the nearest CDN server, reducing the distance and time it takes to load.",
		},
		{
			question: 'What types of content can be delivered via CDN?',
			answer: 'CDN can deliver various types of content, including images, videos, stylesheets, scripts, and other static files.',
		},
		{
			question: 'Is CDN only beneficial for large websites?',
			answer: 'No, CDN benefits websites of all sizes. While larger websites may experience more significant performance improvements, smaller sites can also benefit from faster load times and improved reliability.',
		},
		{
			question: 'Does CDN impact SEO?',
			answer: 'Yes, a CDN can positively impact SEO by improving page load times, which is a ranking factor for search engines. Faster-loading websites often rank higher in search results.',
		},
		{
			question: 'What is the cost of using a CDN?',
			answer: 'At CDNR we charge $1 per URL per month for unlimited traffic. Other CDNs may charge based on bandwidth, file size, or number of requests.',
		},
		{
			question: 'Can I use a CDN with SSL (HTTPS)?',
			answer: 'Yes, all our urls are served over HTTPS.',
		},
		{
			question: 'How does a CDN enhance website security?',
			answer: 'CDNs can provide an additional layer of security by offering features like DDoS protection, web application firewall (WAF), and secure socket layer (SSL) support. This helps protect your website from various online threats.',
		},
	]

	// * ---
	// *	Return: View
	// * ---
	return (
		<Box sx={{ pb: 2 }}>
			{map(questions, (question, index) => (
				<Accordion key={`panel-${index}`}>
					<AccordionSummary aria-controls={`panel-${index}-content`} id={`panel-${index}-header`}>
						<Typography>{question.question}</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>{question.answer}</Typography>
					</AccordionDetails>
				</Accordion>
			))}
		</Box>
	)
}
