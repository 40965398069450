// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import debug from 'debug'

import { FormControl, FormControlLabel, FormHelperText, Slider, Typography } from '@mui/material'
import { get, min } from 'lodash'

import { IFieldProps } from 'globals/IFieldProps'

import { helperText } from 'components/Forms/helperHelper'

// ? ---
// ?	Types
// ? ---
type Props = {
	formik: any
	field: IFieldProps
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Global-Dialogs-UpsertFields-SliderRange'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function SliderRange({ field, formik }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const helper = helperText({ field, formik })

	// * ---
	// *	Method: onChange
	// * ---
	const onChange = (value: number | number[]) => {
		if (field?.config?.maxAllowed) {
			formik.setFieldValue(field.property, `${min([field?.config?.maxAllowed, value])}`)
		} else {
			formik.setFieldValue(field.property, `${value}`)
		}
	}

	// * ---
	// *	Return
	// * ---
	return (
		<FormControl variant='outlined' fullWidth>
			<Typography component='legend' variant='caption' sx={{ ml: 1, mr: 1 }}>
				{field.label}
			</Typography>
			<FormControlLabel
				control={
					<Slider
						id={field.property}
						name={field.property}
						value={parseFloat(
							get(formik?.values, field.property) === ''
								? field.defaultValue || field?.config?.min || 0
								: get(formik?.values, field.property)
						)}
						onChange={(event, value) => onChange(value)}
						valueLabelDisplay='auto'
						step={field?.config?.step || 1}
						min={field?.config?.min || 0}
						max={field?.config?.max || 100}
						marks={field?.config?.marks !== false}
						valueLabelFormat={(value) => field?.config?.valueMap?.[`${value}`] || value}
					/>
				}
				label={
					<Typography sx={{ minWidth: '80px', textAlign: 'right' }} variant={'caption'}>
						{field?.config?.valueMap?.[
							`${get(formik?.values, field.property, field.defaultValue || field?.config?.min || 0)}`
						] || get(formik?.values, field.property, field.defaultValue || field?.config?.min || 0)}
					</Typography>
				}
				sx={{ ml: 0.5, mr: 0.5 }}
			/>
			{helper.active && <FormHelperText error={helper.isError}>{helper.message}</FormHelperText>}
		</FormControl>
	)
}
