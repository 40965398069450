// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import debug from 'debug'

import { GoogleAnalytics } from 'nextjs-google-analytics'

import { GOOGLE_ANALYTICS_APP_ID } from 'globals/constants/integrations'

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-AnalyticsApp'
const log = debug(`app:${namespace}`)

// ? ---
// ?	View
// ? ---
export default function AnalyticsApp(): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')

	// * ---
	// *	Return: GA Tag
	// * ---
	return <GoogleAnalytics trackPageViews gaMeasurementId={GOOGLE_ANALYTICS_APP_ID} />
}
