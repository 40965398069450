// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import axios from 'axios'
import debug from 'debug'
import { useFormik } from 'formik'
import { useDebounce } from 'use-debounce'
import * as yup from 'yup'

import { Button, Card, CardContent, Grid, Typography, useTheme } from '@mui/material'
import { get, isEmpty, isString } from 'lodash'

import { MAX_AGE_OPTIONS } from 'globals/constants/values'

import { useAuth } from 'components/AuthProvider'
import Field from 'components/Forms/Field'
import LoginSignupDialog from 'components/LoginSignupDialog'

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-AddUrl'
const log = debug(`app:${namespace}`)

// ? ---
// ?	View
// ? ---
export default function AddUrl(): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const { getToken, reload } = useAuth()
	const theme = useTheme()

	// * ---
	// *	State
	// * ---
	const [authDialogOpen, $authDialogOpen] = React.useState(false)

	// * ---
	// *	Method: Close Auth Dialog
	// * ---
	const closeAuthDialog = () => {
		$authDialogOpen(false)
	}

	// * ---
	// *	Formik
	// * ---
	const formik = useFormik({
		validationSchema: yup.object({
			url: yup.string().test('is-valid-url', 'Must be a fully qualified URL.', (value: any) => {
				if (isString(value) && !isEmpty(value)) {
					return /^((https?|ftp):)?\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(
						value
					)
				}
				return false
			}),
			name: yup
				.string()
				.matches(/^[A-Za-z0-9_\-]+$/gm, 'Only letters, numbers, underscores, and dashes can be used.'),
		}),
		initialValues: {
			url: '',
			name: '',
			maxAge: 60 * 60 * 24 * 30,
		},
		onSubmit: async (values) => {
			log('onSubmit', values)

			const response = await axios
				.post(
					`/api/urls`,
					{
						...values,
					},
					{
						headers: {
							token: getToken(),
						},
					}
				)
				.then((response) => {
					return response.data
				})
				.catch((err) => {
					log('!! API Boost', err)
					switch (get(err, 'response.status')) {
						case 400:
						case 500:
							const urlError = get(err, 'response.data.message', 'There was an error boosting this URL')
							formik.setErrors({
								url: urlError,
							})
							break
						case 401:
							$authDialogOpen(true)
							break
						case 409:
							formik.setErrors({
								url: `You have already boosted this url.`,
							})
							break
					}
				})

			log('response', response)
			reload()
		},
	})

	const [debouncedSaving] = useDebounce(formik.isSubmitting, 1000, { leading: true })

	// * ---
	// *	Return: View
	// * ---
	return (
		<>
			<Card sx={{ mb: 2 }}>
				<form onSubmit={formik.handleSubmit}>
					<CardContent sx={{ pb: `${theme.spacing()} !important` }}>
						<Typography variant={'h2'}>Boost a URL</Typography>
						<Grid
							container
							direction='row'
							justifyContent='space-between'
							alignItems='flex-start'
							spacing={1}
							sx={{ pt: 1.5 }}
						>
							<Grid item xs={12}>
								<Field
									pb={0.5}
									field={{
										property: 'url',
										type: 'text',
										label: 'URL',
										helper: `The URL you want to boost`,
									}}
									formik={formik}
								/>
							</Grid>
							<Grid item xs={8}>
								<Field
									pb={0.5}
									field={{
										property: 'name',
										type: 'text',
										label: 'Name (Optional)',
										helper: 'Remember this URL by giving it a name',
									}}
									formik={formik}
								/>
							</Grid>
							<Grid item xs={4}>
								<Field
									pb={0.5}
									field={{
										property: 'maxAge',
										type: 'dropdown',
										label: 'Max Age',
										helper: `How long should a client cache this URL?`,
										options: MAX_AGE_OPTIONS,
									}}
									formik={formik}
								/>
							</Grid>
						</Grid>
					</CardContent>
					<CardContent
						sx={{
							borderTop: `1px solid ${theme.palette.divider}`,
							pb: `${theme.spacing()} !important`,
							textAlign: 'right',
						}}
					>
						<Button
							type='submit'
							variant='contained'
							fullWidth
							size={'large'}
							disabled={debouncedSaving}
							sx={{ fontWeight: 800 }}
						>
							Boost for $1
						</Button>
					</CardContent>
				</form>
			</Card>
			<LoginSignupDialog state={authDialogOpen} close={closeAuthDialog} />
		</>
	)
}
