// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import axios from 'axios'
import debug from 'debug'

import { Button, Card, CardContent, Grid, IconButton, List, ListItem, Stack, Typography, useTheme } from '@mui/material'
import Icon from '@mdi/react'
import { find, get, isEmpty, isNull, map } from 'lodash'

import { icons } from 'globals/constants/icons'
import { MAX_AGE_OPTIONS } from 'globals/constants/values'

import { useAuth } from 'components/AuthProvider'
import cdnUrl from 'helpers/cdnUrl'

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-AccountSummary'
const log = debug(`app:${namespace}`)

// ? ---
// ?	View
// ? ---
export default function AccountSummary(): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const { getToken, logout, openPlan, reload, user } = useAuth()
	const theme = useTheme()

	const addonQuality = get(
		find(user?.Account.CurrentSubscription.SubscriptionAddOns, (addon) => !isNull(addon.RenewalDate)),
		'Quantity',
		0
	)
	const urlCredits = addonQuality + 1
	const urlData = JSON.parse(user?.Account?.CdnrData || '[]')

	// * ---
	// *	State
	// * ---
	const [busy, $busy] = React.useState(false)

	// * ---
	// *	Method: Remove URL
	// * ---
	const removeUrl = async (url: { id: string; name: string; url: string; maxAge: number }) => {
		$busy(true)
		const response = await axios
			.delete(`/api/urls/${url.id}`, {
				headers: {
					token: getToken(),
				},
			})
			.then((response) => {
				return response.data
			})
			.catch((err) => {
				log('!! Delete URL', err)
			})

		log('removeUrl', url, response)
		reload()
		$busy(false)
	}

	// * ---
	// *	Method: Remove URL
	// * ---
	const refreshUrl = async (url: { id: string; name: string; url: string; maxAge: number }) => {
		$busy(true)
		const response = await axios
			.post(
				`/api/urls/${url.id}`,
				{},
				{
					headers: {
						token: getToken(),
					},
				}
			)
			.then((response) => {
				return response.data
			})
			.catch((err) => {
				log('!! Refresh URL', err)
			})

		log('refreshUrl', url, response)
		reload()
		$busy(false)
	}

	// * ---
	// *	Return: View
	// * ---
	return (
		<Card sx={{ mb: 2 }}>
			<CardContent sx={{ pb: `${theme.spacing()} !important` }}>
				<Grid
					container
					direction='row'
					justifyContent='space-between'
					alignItems='flex-start'
					spacing={1}
					sx={{ maxHeight: '78vh' }}
				>
					<Grid item xs={6}>
						<Card
							sx={{
								mb: 2,
								pt: 1,
								pl: 1,
								pr: 1,
								pb: 0.5,
								backgroundColor: theme.palette.background.default,
							}}
						>
							<Typography variant={'body1'} sx={{ textAlign: 'center', fontSize: '300%', lineHeight: 1 }}>
								{urlData.length}
							</Typography>
							<Typography variant={'body2'}>URLs</Typography>
						</Card>
					</Grid>
					<Grid item xs={6}>
						<Card
							fullWidth
							component={Button}
							onClick={() => openPlan()}
							sx={{
								display: 'block',
								textAlign: 'left',
								mb: 2,
								pt: 1,
								pl: 1,
								pr: 1,
								pb: 0.5,
								fontSize: 'inherit !important',
								backgroundColor: theme.palette.background.default,
							}}
						>
							<Typography
								variant={'body1'}
								sx={{
									textAlign: 'center',
									fontSize: '300%',
									lineHeight: 1,
								}}
							>
								{urlCredits}
							</Typography>
							<Typography variant={'body2'}>Credits</Typography>
						</Card>
					</Grid>
				</Grid>

				<List dense={true}>
					{map(urlData, (url) => (
						<ListItem key={url.id} sx={{ pl: 0, pr: 1, pb: 1 }}>
							<Grid
								container
								direction='row'
								justifyContent='space-between'
								alignItems='center'
								spacing={1}
							>
								<Grid item xs={9}>
									<Typography
										variant={'body1'}
										sx={{
											mb: 0.5,
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											textOverflow: 'ellipsis',

											'& a': {
												color: `${theme.palette.primary.main} !important`,
												textDecoration: 'none',
											},
											'& a:hover': {
												textDecoration: 'underline',
											},
										}}
									>
										<a
											href={cdnUrl(user, url)}
											target={'_blank'}
											rel={'nofollow noopener noreferrer'}
										>
											{cdnUrl(user, url)}
										</a>
									</Typography>
									<Typography
										variant={'body2'}
										sx={{
											color: theme.palette.text.secondary,
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
										}}
									>
										{!isEmpty(url.name) ? url.name : url.url}
									</Typography>
									<Typography
										variant={'body2'}
										sx={{
											color: theme.palette.text.secondary,
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
										}}
									>
										Max Age: {get(find(MAX_AGE_OPTIONS, { value: url.maxAge }), 'label', '1 Month')}
									</Typography>
								</Grid>
								<Grid item xs={2} sx={{ textAlign: 'right' }}>
									<Stack direction={'row'} spacing={1}>
										<IconButton
											aria-label='refresh'
											onClick={() => refreshUrl(url)}
											disabled={busy}
										>
											<Icon path={icons.refreshIcon} size={0.8} />
										</IconButton>
										<IconButton aria-label='delete' onClick={() => removeUrl(url)} disabled={busy}>
											<Icon path={icons.removeIcon} size={0.8} />
										</IconButton>
									</Stack>
								</Grid>
							</Grid>
						</ListItem>
					))}
				</List>
			</CardContent>
			<CardContent
				sx={{
					borderTop: `1px solid ${theme.palette.divider}`,
					pb: `${theme.spacing()} !important`,
					textAlign: 'right',
				}}
			>
				<Button onClick={() => openPlan()}>Plan</Button>
				<Button onClick={() => logout()}>Logout</Button>
			</CardContent>
		</Card>
	)
}
