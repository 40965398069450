// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import debug from 'debug'

import { Box, CircularProgress, Grid } from '@mui/material'

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Loading'
const log = debug(`app:${namespace}`)

// ? ---
// ?	View
// ? ---
export default function View(): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')

	// * ---
	// *	Return: View
	// * ---
	return (
		<Grid container direction='column' justifyContent='space-between' alignItems='stretch'>
			<Grid item></Grid>
			<Grid item>
				<Box sx={{ mb: 2, textAlign: 'center' }}>
					<Box sx={{ position: 'relative', mb: 1 }}>
						<CircularProgress size={150} thickness={2} />
					</Box>
				</Box>
			</Grid>
			<Grid item></Grid>
		</Grid>
	)
}
