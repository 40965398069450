// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import debug from 'debug'

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { compact, find, get, map } from 'lodash'

import { IFieldProps } from 'globals/IFieldProps'

// ? ---
// ?	Types
// ? ---
type Props = {
	formik: any
	field: IFieldProps
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Global-Dialogs-UpsertFields-Dropdown'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function Dropdown({ field, formik }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')

	// * ---
	// *	Method: Get Options
	// * ---
	const getOptions = React.useCallback(() => {
		if (field.optionsFunc) {
			return compact(field.optionsFunc(get(formik, 'values', {})))
		} else if (field.options) {
			return compact(field.options)
		} else {
			return []
		}
	}, [field, formik])

	// * ---
	// *	Method: Get Current Values
	// * ---
	const getCurrentValue = React.useCallback(() => {
		const value = get(formik?.values, field.property)
		const options = getOptions()
		if (find(options, { value })) {
			return value
		} else {
			const temp = get(field, 'defaultValue', get(options, '[0].value', ''))
			if (get(formik?.values, field.property) !== temp) {
				formik.setFieldValue(field.property, temp)
			}
			return temp
		}
	}, [field, formik, getOptions])

	// * ---
	// *	Return
	// * ---
	return (
		<FormControl variant='filled' fullWidth>
			<InputLabel id={`${field.property}-label`}>{field.label}</InputLabel>
			<Select
				labelId={`${field.property}-label`}
				id={field.property}
				name={field.property}
				value={getCurrentValue()}
				label={field.label}
				onChange={(event) => formik.setFieldValue(field.property, event.target.value)}
			>
				{map(getOptions(), (option) => (
					<MenuItem key={option.value} value={option.value}>
						{option.label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	)
}
