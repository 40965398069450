// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import debug from 'debug'

import Image from 'next/image'
import { Box, Container, Typography, useTheme } from '@mui/material'

import WaitForAuth from 'components/____WaitForAuth'
import AccountSummary from 'components/AccountSummary'
import AddUrl from 'components/AddUrl'
import AnalyticsApp from 'components/AnalyticsApp'
import Faq from 'components/Faq'

// ? ---
// ?	Constants
// ? ---
const namespace = 'views-index-view'
const log = debug(`app:${namespace}`)

// ? ---
// ?	View
// ? ---
export default function View(): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')
	const theme = useTheme()

	// * ---
	// *	Return: View
	// * ---
	return (
		<>
			<AnalyticsApp />
			<Container maxWidth='sm'>
				<Box sx={{ textAlign: 'center', p: 2 }}>
					<Image src='/cdnr-logo.svg' alt={'CDNR Logo'} height={100} width={300} priority />
					<Typography
						sx={{
							fontWeight: 'bold',
							pt: 2,
						}}
					>
						Rapid, Reliable, Refreshing CDN.
					</Typography>
					<Typography
						sx={{
							pt: 2,
							'& a': {
								color: `${theme.palette.primary.main} !important`,
								textDecoration: 'none',
							},
							'& a:hover': {
								textDecoration: 'underline',
							},
						}}
					>
						{'The team behind the awesome '}
						<a href={'https://does.qa'}>codeless test automation</a> {'tool '}
						<a href={'https://does.qa'}>DoesQA</a>{' '}
						{`created CDNR. We couldn't find the tool we needed, so we built it.`}
					</Typography>
				</Box>
				<AddUrl />

				<WaitForAuth>
					<AccountSummary />
				</WaitForAuth>

				<Faq />

				<Typography
					sx={{
						pt: 2,
						pb: 2,
						textAlign: 'center',
						'& a': {
							color: `${theme.palette.primary.main} !important`,
							textDecoration: 'none',
						},
						'& a:hover': {
							textDecoration: 'underline',
						},
					}}
				>
					{'Love CDNR? Check out our '}
					<a href={'https://does.qa'}>codeless test automation</a>
					{' tool.'}
				</Typography>
			</Container>
		</>
	)
}
