// ? ---
// ?	Imports
// ? ---
import { isEmpty } from 'lodash'

import { CDN_LINK_BASE_URL } from 'globals/constants/urls'

import { IOutsetaUser } from 'components/AuthProvider'

// ? ---
// ?	Types
// ? ---

// ? ---
// ?	Props
// ? ---
export default function cdnUrl(
	user: IOutsetaUser | null,
	urlObject: { id: string; name: string; url: string; maxAge: number }
) {
	if (!user || isEmpty(urlObject)) return ''
	return `${CDN_LINK_BASE_URL}/${user?.Account.Uid}-${urlObject.id}`
}
