// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import debug from 'debug'

import { FormControl, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import Icon from '@mdi/react'
import { compact, find, get, map } from 'lodash'

import { icons } from 'globals/constants/icons'
import { IFieldProps } from 'globals/IFieldProps'

// ? ---
// ?	Types
// ? ---
type Props = {
	formik: any
	field: IFieldProps
}

// ? ---
// ?	Constants
// ? ---
const namespace = 'components-Global-Dialogs-UpsertFields-ButtonGroup'
const log = debug(`app:${namespace}`)

// ? ---
// ?	Component
// ? ---
export default function ButtonGroup({ field, formik }: Props): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')

	// * ---
	// *	Method: Get Options
	// * ---
	const getOptions = React.useCallback(() => {
		if (field.optionsFunc) {
			return compact(field.optionsFunc(get(formik, 'values', {})))
		} else if (field.options) {
			return compact(field.options)
		} else {
			return []
		}
	}, [field, formik])

	// * ---
	// *	Method: Get Current Values
	// * ---
	const getCurrentValue = React.useCallback(() => {
		const value = get(formik?.values, field.property)
		const options = getOptions()
		if (find(options, { value })) {
			return value
		} else {
			const temp = get(options, '[0].value', '')
			if (get(formik?.values, field.property) !== temp) {
				formik.setFieldValue(field.property, temp)
			}
			return temp
		}
	}, [field, formik, getOptions])

	// * ---
	// *	Return
	// * ---
	return (
		<FormControl variant='outlined' fullWidth>
			<Typography component='legend' variant='caption' sx={{ ml: 1, mr: 1, mb: 0.5 }}>
				{field.label}
			</Typography>
			<ToggleButtonGroup
				id={field.property}
				value={getCurrentValue()}
				onChange={(event, newValue) => formik.setFieldValue(field.property, newValue)}
				aria-label={field.label}
				exclusive
				fullWidth
				sx={{ pl: 0, pr: 0 }}
			>
				{map(getOptions(), (option) => (
					<ToggleButton key={option.value} value={option.value} aria-label={option.label}>
						<Icon path={icons[option.icon]} size={1} />
					</ToggleButton>
				))}
			</ToggleButtonGroup>
		</FormControl>
	)
}
